.root {
  display: grid;
  position: relative;
  width: 100%;
  height: 100%;
  align-content: flex-start;
  justify-items: center;
  min-width: 0;
  min-height: 0;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
  padding: 0px;
}
.root > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .root > * {
    grid-column: 4;
  }
}
.section {
  display: grid;
  position: relative;
  align-content: center;
  justify-items: center;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
  height: 100vh;
  background: url("./images/zowbgSvg.svg") top 50% left 50% / cover repeat;
  grid-column-start: 1 !important;
  grid-column-end: -1 !important;
  grid-template-columns:
    var(--plsmc-viewport-gap) 1fr minmax(0, var(--plsmc-wide-chunk))
    min(
      var(--plsmc-standard-width),
      calc(100% - var(--plsmc-viewport-gap) - var(--plsmc-viewport-gap))
    )
    minmax(0, var(--plsmc-wide-chunk)) 1fr var(--plsmc-viewport-gap);
}
.section > * {
  grid-column: 4;
}
@media (min-width: 0px) and (max-width: 768px) {
  .section > * {
    grid-column: 4;
  }
}
